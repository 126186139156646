import React, { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { useAuth } from '../context/AuthContext'
import logo from '../images/small_logo.png'

const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ')
}

const Navbar = () => {
  // @ts-ignore
  const { authState: { me, event, seller }, logout } = useAuth()

  const onClick = () => {
    logout()
  }

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <div>
          <div className="relative flex justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">

            </div>
            <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                {/* <img
                  className="mx-auto h-12 w-auto mr-2.5"
                  src={seller!.image}
                  alt="small-logo"
                /> */}
                <h1 className="text-3xl font-bold leading-tight text-black">
                  {event != null ? "Statistieken" : "Mijn events"}
                </h1>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                      <span className="font-medium leading-none text-white">{me!.name[0].toUpperCase()}</span>
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')} style={{ width: '100%', textAlign: 'left' }} onClick={onClick}>
                          Log uit
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  )
}

export default Navbar