import React from 'react'
import ReactDOM from 'react-dom/client'
import { AppProviders } from './providers/AppProviders'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'moment/min/locales'
import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <AppProviders>
    <App />
  </AppProviders>
  // <React.StrictMode>
  //   <AppProviders>
  //     <App />
  //   </AppProviders>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
