import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Table from '../components/Table'
import type { Category, Ticket } from '../context/AuthContext'

const Statistics = () => {
  const { authState: { event, eventStats: stats }, setEvent, setEventStats } = useAuth()
  const navigate = useNavigate()

  const goToEvents = () => {
    setEvent(null)
    setEventStats(null)
    navigate(`/events`)
  }

  const renderTables = (): React.ReactNode[] => stats!.categories.map((category: Category) => {
    const tickets = stats!.tickets.filter((ticket: Ticket) => ticket.categoryRef === category.ref)
    const generalSoldTickets = tickets.reduce((acc, ticket: Ticket) => acc + ticket.pos + ticket.free + ticket.sold, 0)
    const generalAvailableTickets = category.amount - generalSoldTickets
    return <Table category={category} tickets={tickets} generalAvailableTickets={generalAvailableTickets} generalSoldTickets={generalSoldTickets} />
  })

  const renderTotals = () => {
    const ticketsSoldOverCategories = stats!.tickets.reduce((acc, ticket) => acc + ticket.pos + ticket.free + ticket.sold, 0)
    const ticketsAvailableOverCategories = stats!.categories.reduce((acc, category: Category) => {
      const tickets = stats!.tickets.filter((ticket: Ticket) => ticket.categoryRef === category.ref)
      const generalSoldTickets = tickets.reduce((acc, ticket) => acc + ticket.pos + ticket.free + ticket.sold, 0)
      const generalAvailableTickets = category.amount - generalSoldTickets

      return acc + generalAvailableTickets
    }, 0)
    return (
      <div className="border rounded border-gray-200 px-4 sm:px-6 lg:px-8 mb-4">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="grid grid-cols-5">
              <div className="text-sm font-semibold text-gray-900 py-3.5 pl-4 pr-3">
                <span>Total</span>
              </div>
              <div className="text-sm font-semibold text-gray-900 px-3 py-3.5" />
              <div className="text-sm font-semibold text-gray-900 px-3 py-3.5">
                <span>{ticketsSoldOverCategories}</span>
              </div>
              <div className="text-sm font-semibold text-gray-900 px-3 py-3.5">
                <span>{ticketsAvailableOverCategories}</span>
              </div>
              <div className="text-sm font-semibold text-gray-900 px-3 py-3.5">
                <span>{stats!.maxAmount}</span>
              </div>
            </div>
          </div>
        </div>
    </div>
    )
  }

  useEffect(() => {
    if (event?.seating.active) {
      const ticketTypes = event!.tickets.map((ticket: Ticket) => {
        const eventStatsTickets = stats!.tickets.find((t) => t._id === ticket._id)
        return {
          categoryRef: ticket.categoryRef,
          price: ticket.price,
          active: ticket.active,
          v: ticket.amount - eventStatsTickets!.sold
        }
      })
      const categories = event!.categories.map((category: Category) => {
        const tickets = stats!.tickets.filter((ticket) => ticket.categoryRef === category.ref).reduce((acc: number, ticket) => acc + ticket.sold, 0)
        return {
          ref: category.ref,
          seatingReference: category.seatingReference,
          v: category.amount - tickets
        }
      })
  
      // @ts-ignore
      const instance = window.VIInit()
      instance.initSeatSelector({
          holder: "map",
          baseUrl: "https://seatmap.vivenu.com",
          eventId: event!.seating.eventId,
          options: {
            ticketTypes,
            categories,
            contingents: event!.seating.contingents
          }
      })
    }
  }, [])

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex flex-row my-3 justify-between gap-x-3">
        <span className="font-medium text-lg">{event!.name}</span>
        <div className="text-indigo-600 hover:text-indigo-900 font-medium flex-shrink-0 cursor-pointer" onClick={goToEvents}>Ga terug</div>
      </div>
      <div className="h-full overflow-scroll">
        <div className="flex flex-col gap-y-3">
          {stats && renderTables()}
          {stats && renderTotals()}
        </div>
        {event?.seating.active && <div id="map" className="h-96 pb-4" />}
      </div>
    </div>
  
  )
}

export default Statistics