import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Events from './pages/Events'
import Statistics from './pages/Statistics'

const AuthenticatedApp = () => (
  <div className="px-6 flex flex-col h-full">
    <Navbar />
    <Routes>
      <Route path="/events" element={<Events />} />
      <Route path="/events/:id/statistics" element={<Statistics />} />
    </Routes>
  </div>
)

export default AuthenticatedApp
