import React from 'react'
import { useAuth } from './context/AuthContext'
import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'

const App = () => {
  const { authState: { me, tokens } } = useAuth()
  return (
    me != null ? <AuthenticatedApp /> : <UnauthenticatedApp />
  )
}

export default App
