import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import update from 'immutability-helper'
import { useAuth } from '../context/AuthContext'
import Alert from '../components/Alert'
import Email from '../components/Email'
import Password from '../components/Password'

const Login = () => {
  // @ts-ignore
  const { login } = useAuth()
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const [emailState, setEmail] = useState({ value: '', errorMessage: '' })
  const [passwordState, setPassword] = useState({ value: '', errorMessage: '' })
  const [isLoading, setIsLoading] = useState(false)

  const formIsInValid = (): boolean => {
    let error = false
    const { value: email } = emailState
    const { value: password } = passwordState

    if (isEmpty(email)) {
      error = true
      setEmail((prevState: any) =>
        update(prevState, {
          errorMessage: { $set: 'Wat is jouw e-mail adres?' }
        })
      )
    } else if (!isEmail(email)) {
      error = true
      setEmail((prevState: any) =>
        update(prevState, {
          errorMessage: { $set: 'Een geldig e-mailadres bestaat uit een naam en een @-symbool gevolgd door een domeinnaam (bijvoorbeeld .com)' }
        })
      )
    }
    if (isEmpty(password)) {
      error = true
      setPassword((prevState: any) =>
        update(prevState, {
          errorMessage: { $set: 'Wat is jouw wachtwoord?' }
        })
      )
    }
    return error
  }

  const clearErrors = () => {
    setError('')
    setEmail((prevState: any) => 
      update(prevState, {
        errorMessage: { $set: '' }
      })
    )
    setPassword((prevState: any) => 
      update(prevState, {
        errorMessage: { $set: '' } 
      })
    )
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail((prevState: any) => 
      update(prevState, { 
        value: { $set: value }, 
        errorMessage: { $set: '' }
      })
    )
  }
  
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setPassword((prevState: any) => 
      update(prevState, { 
        value: { $set: value }, 
        errorMessage: { $set: '' } 
      })
    )
  }
    
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    clearErrors()

    if (isLoading) return
    if (formIsInValid()) return
    setIsLoading(true)
    try {
      const { value: email } = emailState
      const { value: password } = passwordState

      await login(email, password)
      // navigate(`/events`, { replace: true })
    } catch(error) {
      setError('E-mail adres en/of wachtwoord zijn niet correct')
      setIsLoading(false)
    }
  }

  return (
    <>
      {/* <div style={{ height: '800px', top: '-470px', position: 'absolute', width: '100%', overflowX: 'hidden', zIndex: '-1', backgroundColor: '#FBC000', transform: 'skewY(-6deg)'}}></div> */}
      <div className="min-h-full flex flex-col justify-center py-12 px-6 lg:px-8" style={{ backgroundImage: 'url("https://s3.eu-central-1.amazonaws.com/yt-s3/9299025a-8a6d-477d-be95-63d3fd05013b.jpg")', backgroundSize: 'cover', backgroundPosition: '50%'}}>
        <div className="bg-white sm:mx-auto sm:w-full sm:max-w-md" style={{ borderRadius: '4px', boxShadow: 'rgba(0, 0, 0, 0.1) 6px -8px 25px -5px, transparent 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px'}}>
          <div className="pt-8 px-4">
            <img
              className="mx-auto h-12 w-auto"
              src="https://s3.eu-central-1.amazonaws.com/yt-s3/9de93ef9-fa49-4ba4-adf9-1574113688b8.png"
              alt="ticket.monster"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Log in op je account</h2>
          </div>

          <div className="py-8 px-4 sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              {error !== '' && (<Alert message={error} />)}
              <Email emailState={emailState} onChange={onChangeEmail} />

              <Password passwordState={passwordState} onChange={onChangePassword} />

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Log in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login