import React from 'react'
import type { Category, Ticket } from '../context/AuthContext'

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  category: Category,
  generalAvailableTickets: number,
  generalSoldTickets: number,
  tickets: Array<Ticket>
}

const Table = ({ category, generalAvailableTickets, generalSoldTickets, tickets }: Props) => {
  const renderTickets = (): React.ReactNode[] => [
    ...tickets.map((ticket: Ticket, ticketIdx: number) => {
      const sold = ticket.pos + ticket.free + ticket.sold
      const availableForTicketType = ticket.amount - sold
      const available = availableForTicketType < generalAvailableTickets ? availableForTicketType : generalAvailableTickets
      return (
        <>
          <div className="border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-500">
            {ticket.name}
          </div>
          <div className="border-b border-gray-200 px-3 py-4 text-sm text-gray-500">
            {ticket.price}
          </div>
          <div className="border-b border-gray-200 px-3 py-4 text-sm text-gray-500">
            {sold}
          </div>
          <div className="border-b border-gray-200 px-3 py-4 text-sm text-gray-500">
            {available}
          </div>
          <div className="border-b border-gray-200 px-3 py-4 text-sm text-gray-500">
            {ticket.amount}
          </div>
        </>
      )    
    }),
    <div className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
      Total
    </div>,
    <div className="px-3 py-4 text-sm text-gray-500" />,
    <div className="px-3 py-4 text-sm text-gray-500">
      {generalSoldTickets}
    </div>,
    <div className="px-3 py-4 text-sm text-gray-500">
      {generalAvailableTickets}
    </div>,
    <div className="px-3 py-4 text-sm text-gray-500">
      {category.amount}
    </div>
  ]


  return (
    <div className="border rounded border-gray-200 px-4 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="grid grid-cols-5">
              <div className="border-b border-gray-200 text-sm font-bold text-gray-900 py-3.5 pl-4 pr-3">
                <span>{category.name}</span>
              </div>
              <div className="border-b border-gray-200 text-sm font-semibold text-gray-900 px-3 py-3.5">
                <span>Price</span>
              </div>
              <div className="border-b border-gray-200 text-sm font-semibold text-gray-900 px-3 py-3.5">
                <span>Sold</span>
              </div>
              <div className="border-b border-gray-200 text-sm font-semibold text-gray-900 px-3 py-3.5">
                <span>Available</span>
              </div>
              <div className="border-b border-gray-200 text-sm font-semibold text-gray-900 px-3 py-3.5">
                <span>Volume</span>
              </div>
              {renderTickets()}
            </div>
          </div>
        </div>
    </div>
  )
}

export default Table

