import { CalendarIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import { startOfDay } from 'date-fns'
import { debounce, orderBy } from 'lodash'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import type { Category, Event, EventStats, Seller, Ticket } from '../context/AuthContext'
import useAccessTokenProvider from '../providers/AccessTokenProvider'

const vivenuApi = process.env.REACT_APP_VIVENU_API_ENDPOINT

type Params = {
  skip: number,
  amount: number,
  total: number
  start: Date | null,
  end: Date | null
}

const initParams = {
  skip: 0,
  amount: 0,
  total: 0,
  start: startOfDay(new Date()),
  end: null
}

const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ')
}

const Events = () => {
  const { authState: { seller, sellers, tokens }, signTokenForSeller, setEvent, setEventStats, setSellers } = useAuth()
  const navigate = useNavigate()
  const [token] = useAccessTokenProvider()
  const [search, setSearch] = useState<string>('')
  const [events, setEvents] = useState<Array<Event>>([])
  const [stats, setStats] = useState<Array<EventStats>>([])
  const [params, setParams] = useState<Params>(initParams)
  const [totalEventsForSeller, setTotalEventsForSeller] = useState<{ [key: string]: { amount: number, total: number } }>(sellers.reduce((acc, seller) => ({
    [seller._id]: { total: 0, amount: 0 }
  }), {}))
  const [fetchingStats, setFetchingStats] = useState<boolean>(true)
  const [sellerSearch, setSellerSearch] = useState<string>('')
  const top = useMemo(() => Math.round(30 / sellers.length), [sellers])

  const onClickEvent = (event: Event, eventStats: EventStats) => () => {
    setEvent(event)
    setEventStats(eventStats)
    navigate(`/events/${event._id}/statistics`)
   }

  const renderDate = (event: Event) => {
    const { start, timezone } = event
    const momentDate = moment(start)
       .tz(timezone)

    const dayFull = momentDate.format('dddd')
    const date = momentDate.format('LL')
    const time = momentDate.format('HH:mm')
    const str = `${dayFull}, ${date} - ${time}`

    return (
      <div className="mt-2 flex">
        <div className="flex items-center text-sm text-gray-500">
        <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        <p>
          {str}
        </p>
        </div>
      </div>
    )
 }

 const renderSeller = (sellerId: string) => {
  const seller = sellers.find((seller) => seller._id === sellerId)
  return seller.name
 }

  const renderEvents = () => events.map((event: Event) => {
    const eventStats = stats.find((eventStats) => eventStats._id === event._id) as EventStats
    return (
      <div key={`event-${event._id}`} className="rounded border border-gray-300 cursor-pointer" onClick={onClickEvent(event, eventStats)}>
         <div>
            <div className="overflow-hidden">
               <div className="px-4 py-5 sm:px-6">
                  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                     <div className="ml-4 mt-2 flex-1">
                        <span className="mb-2 inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                          {renderSeller(event.sellerId)}
                        </span>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{event.name}</h3>
                        {renderDate(event)}
                     </div>
                     <div className="mt-6 ml-4 flex-1" aria-hidden="true">
                      {fetchingStats ? (
                        <div className="overflow-hidden rounded-lg bg-gray-200 relative mix-blend-multiply">
                          <div className="flex justify-between h-6 rounded-lg bg-indigo-600 animate-progress origin-left-right" />
                        </div>
                      ) : (
                        <div className="overflow-hidden rounded-lg bg-gray-200 relative mix-blend-multiply">
                          <div className="flex justify-between h-6 rounded-lg bg-indigo-600" style={{ width: `${(eventStats.sold/eventStats.maxAmount) * 100}%` }}>
                            <div className="pl-2 pr-1 text-white bg-indigo-600">{eventStats.sold}</div>
                          </div>
                          <div className="absolute mr-2 right-0 top-0">{eventStats.maxAmount}</div>
                        </div>
                      )}
                    </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
  })

  const setStart = () => {
    const paramsForSeller = sellers.reduce((acc, seller) => ({
      [seller._id]: { total: 0, amount: 0 }
    }), {})

    setTotalEventsForSeller(paramsForSeller)
    setParams({
      ...params,
      skip: 0,
      start: startOfDay(new Date()),
      end: null
    })
    fetchEvents(search, true, startOfDay(new Date()), null, paramsForSeller)
  }

  const setEnd = () => {
    const paramsForSeller = sellers.reduce((acc, seller) => ({
      [seller._id]: { total: 0, amount: 0 }
    }), {})

    setTotalEventsForSeller(paramsForSeller)
    setParams({
      ...params,
      skip: 0,
      start: null,
      end: startOfDay(new Date())
    })
    fetchEvents(search, true, null, startOfDay(new Date()), paramsForSeller)
  }

  const fetchMoreEvents = () => {
    setParams({
      ...params,
      skip: params.amount
    })
    fetchEvents(search, false, params.start, params.end, totalEventsForSeller)
  }

  const fetchEvents = async (search: string, init: boolean, start: Date | null, end: Date | null, paramsForSeller?: { [key: string]: { amount: number, total: number } }) => {
    try {
      const allEvents: Array<Event> = []
      const totalEvents: { [key: string]: { amount: number, total: number } } = {}
      let skip = 0
      for (const [sellerId, sellerToken] of Object.entries(tokens!)) {
        const tefs = paramsForSeller?.[sellerId] ?? { amount: 0, total: 0 }
        if (!init && tefs.amount === tefs.total) continue
        skip = tefs.amount
        const fetchParams = search.length > 0 ? {
          skip,
          top,
          ...(start ? { start } : { end }),
          ...(start ? { sortDir: 1 } : { sortDir: -1 }),
          name: search
        } : {
          skip,
          top,
          ...(start ? { start } : { end }),
          ...(start ? { sortDir: 1 } : { sortDir: -1 })
        }
        
        const response = await axios.get(`${vivenuApi}/events`, { headers: { Authorization: `Bearer ${sellerToken}` }, params: fetchParams as any})
        const { rows, total } = response.data
        allEvents.push(...rows)
        totalEvents[sellerId] = { total, amount: skip + rows.length }
      }

      setFetchingStats(true)

      const responses = await Promise.all(allEvents.map((event: any) => axios.get(`${vivenuApi}/stats/events/${event._id}/status`, { headers: { Authorization: `Bearer ${tokens![event.sellerId]}` } })))

      if (skip === 0) {
        setEvents(orderBy(allEvents, ['start'], [start ? 'asc' : 'desc']))
        setStats(responses.map(({ data }) => {
          const { _id, sellerId, name, tickets, categories, maxAmount } = data
          const mappedTickets = tickets.map(({ _id, name, categoryRef, price, amount, active, sold, free, reserved, pos }: Ticket) => ({ _id, name, categoryRef, price, amount, active, sold, free, reserved, pos }))
          const mappedCategories = categories.map(({ _id, name, ref, amount, active }: Category) => ({ _id, name, ref, amount, active }))
          const ticketsSoldOverCategories = mappedTickets.reduce((acc: number, ticket: Ticket) => acc + ticket.pos + ticket.free + ticket.sold, 0)
          return {
            _id,
            sellerId,
            name,
            tickets: mappedTickets,
            categories: mappedCategories,
            sold: ticketsSoldOverCategories,
            maxAmount
          }
        }))
      } else {
        setEvents(orderBy([...events, ...allEvents], ['start'], [start ? 'asc' : 'desc']))
        setStats([...stats, ...responses.map(({ data }) => {
          const { _id, sellerId, name, tickets, categories, maxAmount } = data
          const mappedTickets = tickets.map(({ _id, name, categoryRef, price, amount, active, sold, free, reserved, pos }: Ticket) => ({ _id, name, categoryRef, price, amount, active, sold, free, reserved, pos }))
          const mappedCategories = categories.map(({ _id, name, ref, amount, active }: Category) => ({ _id, name, ref, amount, active }))
          const ticketsSoldOverCategories = mappedTickets.reduce((acc: number, ticket: Ticket) => acc + ticket.pos + ticket.free + ticket.sold, 0)
          return {
            _id,
            sellerId,
            name,
            tickets: mappedTickets,
            categories: mappedCategories,
            sold: ticketsSoldOverCategories,
            maxAmount
          }
        })])
      }

      setTotalEventsForSeller(totalEvents)
      setParams({
        ...params,
        start,
        end
      })

      setFetchingStats(false)
    } catch (error) {
      console.error('Something went wrong')
    }
  }

  const debouncedFetchEvents = useCallback(debounce(fetchEvents, 500), [token])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const paramsForSeller = sellers.reduce((acc, seller) => ({
      [seller._id]: { total: 0, amount: 0 }
    }), {})
    setTotalEventsForSeller(paramsForSeller)
    setSearch(event.target.value)
    debouncedFetchEvents(event.target.value, true, params.start, params.end, paramsForSeller)
  }

  const anySellerHasMoreEvents = Object.values(totalEventsForSeller).some(({ amount, total }) => amount < total)

  useEffect(() => {
    if (events.length === 0 && tokens != null) {
      fetchEvents('', true, startOfDay(new Date()), null)
    }

    return () => {
      debouncedFetchEvents.cancel()
    }
  }, [events, tokens])

  return (
    <div className="flex flex-col overflow-hidden mb-3">
      <div className="flex flex-row flex-wrap my-3 gap-3 items-center">
        <div className="">
          <nav className="flex space-x-4" aria-label="Tabs">
            <a
              className={classNames(
                params.start ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
              )}
              aria-current={params.start ? 'page' : undefined}
              onClick={setStart}
            >
              Komende evenementen
            </a>
            <a
              className={classNames(
                params.end ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
              )}
              aria-current={params.end ? 'page' : undefined}
              onClick={setEnd}
            >
              Eerdere evenementen
            </a>
          </nav>
        </div>
        <input
          className="appearance-none m-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={search}
          placeholder="Zoek op event"
          type="text"
          onChange={handleInputChange}
        />
      </div>
      <div className="h-full overflow-scroll">
        <div className="flex flex-col gap-y-3">
          {renderEvents()}
          {anySellerHasMoreEvents && (<div className="text-indigo-600 hover:text-indigo-900 font-medium flex-shrink-0 cursor-pointer" onClick={fetchMoreEvents}>Laad meer</div>)}
        </div>
      </div>
    </div>
  )
}

export default Events